import { useRef } from 'react';
import styled from 'styled-components';
import EmailEditor from 'react-email-editor';
import sample from './assets/order_ship_design.json';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	height: 100vh;
`;

const Bar = styled.div`
	flex: 1;
	background-color: white;
	color: #000;
	padding: 10px;
	display: flex;
	max-height: 40px;
	box-shadow: 0 0 5px rgba(0,0,0,0.3);
	z-index: 1;

	h1 {
		flex: 1;
		font-size: 16px;
		text-align: left;
	}

	button {
		flex: 1;
		padding: 10px;
		margin-left: 10px;
		font-size: 14px;
		font-weight: bold;
		background-color: #000;
		color: #fff;
		border: 0px;
		max-width: 150px;
		cursor: pointer;
	}
`;

const App = () => {
	const emailEditorRef = useRef(null);

	const saveDesign = () => {
		emailEditorRef?.current?.editor.saveDesign((design) => {
			console.log('saveDesign', design);
			alert('Design JSON has been logged in your developer console.');
		});
	};

	const exportHtml = () => {
		emailEditorRef.current.editor.exportHtml((data) => {
			const { html } = data;
			console.log('exportHtml', html);
			alert('Output HTML has been logged in your developer console.');
		});
	};

	const onDesignLoad = (data) => {
		console.log('onDesignLoad', data);
	};

	const onLoad = () => {
		emailEditorRef.current.editor.addEventListener('onDesignLoad', onDesignLoad);
		emailEditorRef.current.editor.loadDesign(sample);
	};

	const onLoadDesign = () => {
		emailEditorRef.current.editor.addEventListener('onDesignLoad', onDesignLoad);
		emailEditorRef.current.editor.loadDesign(sample);
	};

	return (
		<Container>
			<Bar>
				<h1>Email Template Maker</h1>
				<button onClick={onLoadDesign}>Load Design</button>
				<button onClick={saveDesign}>Save Design</button>
				<button onClick={exportHtml}>Export HTML</button>
			</Bar>
			<EmailEditor ref={emailEditorRef} onLoad={onLoad} />
		</Container>
	);
};

export default App;
